import {useMutation, useQuery} from 'react-query';
import {ApiError, BacketCheckError, BacketCheckValid, Basket, BasketItem} from '../types';
import {BasketService} from '../types/services/BasketService';
import {BASKET_GET} from './keys';

export function useAddBasket() {
    return useMutation<Basket, ApiError, BasketItem>((item: BasketItem) =>
        BasketService.addBasket(item)
    );
}

export function useModifyBasket() {
    return useMutation<Basket, ApiError, BasketItem>((item: BasketItem) =>
        BasketService.modifyBasket(item)
    );
}

export function useGetBasket(enabled: boolean) {
    return useQuery(BASKET_GET, BasketService.getBasket, {
        enabled: enabled,
        refetchOnMount: true,
    });
}

export function useEmptyBasket() {
    return useMutation<boolean, ApiError, string>(BasketService.emptyBasket);
}

export function useCheckBasket() {
    return useMutation<BacketCheckError | BacketCheckValid, ApiError, string>(
        (campaignId: string) => BasketService.checkBasket(campaignId)
    );
}
