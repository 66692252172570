export const USER_GET = 'user:get';
export const USER_GET_URL = 'user:get:url';
export const USER_GET_AUTHOR = 'user:get:author';
export const FAQ_GET = 'faq:get';
export const FAQ_GET_CAMPAIGN = 'faq:get:campaign';
export const SUPPORT_GET = 'support:get';
export const SUPPORT_GET_CAMPAIGN = 'support:get:campaign';
export const CAMPAIGN_GET = 'campaign:get';
export const AUTHOR_GET = 'author:get';
export const CAMPAIGN_GET_URL = 'campaign:get:url';
export const CAMPAIGN_GET_OVERVIEW = 'campaign:get:overview';
export const CAMPAIGN_GET_PACK = 'campaign:get:pack';
export const CAMPAIGN_GET_LIKE = 'campaign:get:like';
export const CAMPAIGN_GET_PARTICIPATION = 'campaign:get:participation';
export const BASKET_GET = 'basket:get';
export const ORDER_GET = 'order:get';
export const ORDERS_GET = 'orders:get';
