import React, {useContext, useEffect, useState} from 'react';
import {useAddBasket, useGetBasket, useModifyBasket} from '../queries/basketQueries';
import {useUser} from '../provider/UserProvider';
import {Basket, BasketItem} from '../types';
import {BASKET_GET} from '../queries/keys';
import {useQueryClient} from 'react-query';

export function BasketProvider(props: {children: React.ReactNode}) {
    const queryClient = useQueryClient();
    const {dbUser} = useUser();
    const {data: dbBasket, refetch: refetchDbBasket, isLoading} = useGetBasket(false);
    const modifyBasketInDb = useModifyBasket();
    const addBasketInDb = useAddBasket();
    const [basket, setBasket] = useState<Basket | null>(null);

    useEffect(() => {
        // console.log('dbUser is = ', dbUser);
        if (dbUser) {
            resolveBasket();
        } else {
            const localBasket = getLocalBasket();
            if (localBasket?.length ?? 0 > 0) {
                setLocalBasket(localBasket);
            }
        }
    }, [dbUser]);

    // est-ce que c'est con ?
    useEffect(() => {
        if (dbBasket) {
            setBasket(dbBasket);
        }
    }, [dbBasket]);

    async function resolveBasket() {
        const localBasket = getLocalBasket();
        if (localBasket?.length ?? 0 > 0) {
            // push local basket in db
            for (const localItem of localBasket) {
                await modifyBasket(localItem);
            }
            localStorage.removeItem(LOCAL_BASKET_KEY);
        } else {
            // take basket from db
            // console.log('DBUSER IS = ', dbUser);
            // console.log('refetchDbBasket at ', Date.now());
            refetchDbBasket();
        }
    }

    function getLocalBasket(): BasketItem[] {
        let localItems = JSON.parse(localStorage.getItem(LOCAL_BASKET_KEY)) as BasketItem[];
        // console.log('local basket = ', localItems);
        return localItems;
    }

    function setLocalBasket(items: BasketItem[]) {
        // console.log('setLocalBasket = ', items);
        localStorage.setItem(LOCAL_BASKET_KEY, JSON.stringify(items));
        // console.log('items = ', items);
        setBasket({items: items});
    }

    function getNewItem(item: BasketItem) {
        const now = new Date(Date.now()).toLocaleDateString();
        let jsonOptions = [];
        for (const option of item.options) {
            jsonOptions.push({
                pack_option_id: option.pack_option_id,
                pack_option_value_id: option.pack_option_value_id,
            });
        }
        return {
            pack_id: item.pack_id,
            quantity: 1,
            delivery: item.delivery,
            options: jsonOptions,
            updated: now,
            added: now,
        };
    }

    async function addBasket(basketItem: BasketItem) {
        // console.log('basketFromDb = ', Boolean(dbUser));
        if (dbUser) {
            const basketData = await addBasketInDb.mutateAsync(basketItem);
            queryClient.setQueryData(BASKET_GET, basketData);
            setBasket(basketData);
            return basketData.items?.length ?? 0;
        } else {
            let localItems = getLocalBasket();
            if (!localItems) {
                const content = [getNewItem(basketItem)];
                setLocalBasket(content);
            } else {
                let exist = false;
                for (const localItem of localItems) {
                    // console.log('item = ', localItem);
                    if (localItem.pack_id == basketItem.pack_id) {
                        let sameOption = true;
                        for (const basketOption of basketItem.options) {
                            for (const localOption of localItem.options) {
                                if (
                                    localOption.pack_option_value_id !=
                                    basketOption.pack_option_value_id
                                ) {
                                    sameOption = false;
                                }
                            }
                        }
                        if (sameOption) {
                            localItem.quantity = localItem.quantity + 1;
                            // TODO : updated date
                            exist = true;
                        }
                    }
                }
                if (!exist) {
                    localItems.push(getNewItem(basketItem));
                }
                setLocalBasket(localItems);
                return localItems.length;
            }
        }
    }

    async function modifyBasket(basketItem: BasketItem) {
        // console.log('modifyBasket');
        // console.log('basketFromDb = ', Boolean(dbUser));
        if (dbUser) {
            const basketData = await modifyBasketInDb.mutateAsync(basketItem);
            queryClient.setQueryData(BASKET_GET, basketData);
            setBasket(basketData);
        } else {
            if (basketItem.quantity == 0) {
                return await removeBasket(basketItem.pack_id);
            }

            let localItems = getLocalBasket();
            if (!localItems) {
                const content = [getNewItem(basketItem)];
                setLocalBasket(content);
            } else {
                let exist = false;
                for (const localItem of localItems) {
                    // console.log('item = ', localItem);
                    if (localItem.pack_id == basketItem.pack_id) {
                        let sameOptions = true;
                        for (const localOption of localItem.options) {
                            for (const basketOption of basketItem.options) {
                                if (
                                    localOption.pack_option_id == basketOption.pack_option_id
                                ) {
                                    if (
                                        localOption.pack_option_value_id !=
                                        basketOption.pack_option_value_id
                                    ) {
                                        sameOptions = false;
                                    }
                                }
                            }
                        }
                        if (sameOptions) {
                            localItem.quantity = basketItem.quantity;
                            exist = true;
                        }
                    }
                }
                if (!exist) {
                    localItems.push(getNewItem(basketItem));
                }
                setLocalBasket(localItems);
            }
        }
    }

    async function removeBasket(packId: string) {
        let localItems = getLocalBasket();

        if (!localItems) {
            return [];
        } else {
            let idx = 0;
            for (const localItem of localItems) {
                if (localItem.pack_id == packId) {
                    localItems.splice(idx, 1);
                }
                idx++;
            }

            setLocalBasket(localItems);
        }
    }

    async function emptyBasket() {
        setLocalBasket([]);
        queryClient.setQueryData<Basket>(BASKET_GET, {items: []});
    }

    async function updateBasket(items: BasketItem[]) {
        // console.log('updateBasket = ', items);
        if (dbUser) {
            queryClient.setQueryData(BASKET_GET, {items: items});
        } else {
            setLocalBasket(items);
        }
    }

    const value = {
        addBasket,
        modifyBasket,
        emptyBasket,
        updateBasket,
        basket,
        isLoading,
    };

    return <BasketContext.Provider value={value} {...props} />;
}

const DEFAULT_CONTEXT_VALUE: BasketContextInfo = {
    addBasket: async (basketItem: BasketItem) => {
        return 0;
    },
    modifyBasket: (basketItem: BasketItem) => {},
    emptyBasket: () => {},
    updateBasket: (items: BasketItem[]) => {},
    basket: null,
    isLoading: false,
};

export type BasketContextInfo = {
    addBasket: (basketItem: BasketItem) => Promise<number>;
    modifyBasket: (basketItem: BasketItem) => void;
    emptyBasket: () => void;
    updateBasket: (items: BasketItem[]) => void;
    basket: Basket;
    isLoading: boolean;
};

export const BasketContext = React.createContext(DEFAULT_CONTEXT_VALUE);

export function useBasket() {
    return useContext(BasketContext);
}

const LOCAL_BASKET_KEY = 'LOCAL_BASKET_KEY';
