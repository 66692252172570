export const Colors = {
    first: '#f3a39d',
    firstHover: '#EC676D',
    second: '#afd8c4',
    secondHover: '#8CCCBE',
    secondLight: 'rgba(140, 204, 190, 0.73)',
    secondBg: '#abd4c0',
    secondDark: '#328371',
    yellow: '#ffd14f',
    yellowHover: '#fcc00f',
    grey: '#4c4c4c',
    somberText: '#777',
    somber: '#c8c8c8',
    dark: '#141414',
    light: '#f0f0f0',
    paypalSilver: '#e5e5e5',
    red: '#CC3400',
    link: '#000dff',
    shadow: '10px 10px 10px 0px rgba(0,0,0,0.1)',
};

export function getGradient() {
    return (
        'linear-gradient(90deg, ' +
        Colors.first +
        ' 0%, ' +
        Colors.yellow +
        ' 35%, ' +
        Colors.second +
        ' 100%)'
    );
}

export function getStepGradient(completed: boolean) {
    if (completed) {
        return (
            'linear-gradient(90deg, ' +
            Colors.secondHover +
            ' 0%, ' +
            Colors.secondHover +
            ' 100%)'
        );
    } else {
        return (
            'linear-gradient(90deg, ' + Colors.secondHover + ' 0%, ' + Colors.yellow + ' 100%)'
        );
    }
}
