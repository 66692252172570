import {
    Box,
    BoxProps,
    Button,
    ButtonProps,
    SxProps,
    Typography,
    TypographyProps,
    useTheme,
} from '@mui/material';
import Link, {LinkProps} from 'next/link';

import {Colors} from './colors';
import DiscordIcon from '../icons/discord';
import {PropsWithChildren} from 'react';

export function CustomButton({sx, ...props}: ButtonProps) {
    const customSx = {
        borderColor: props.disabled ? '#ccc' : 'primary.light',
        borderWidth: 2,
        borderStyle: 'solid',
        color: 'black',
        backgroundColor: 'white',
        boxShadow: 0,
        fontWeight: 'normal',
        '&:hover': {
            boxShadow: 0,
        },
        ...sx,
    };

    return <Button variant="contained" sx={customSx} {...props} />;
}

export function ImportantButton({sx, ...props}: ButtonProps) {
    const customSx = {
        color: 'white',
        backgroundColor: Colors.second,
        boxShadow: 0,
        '&:hover': {
            backgroundColor: Colors.secondHover,
            boxShadow: 0,
        },
        paddingTop: '15px',
        paddingBottom: '15px',
        ...sx,
    };

    return <Button variant="contained" sx={customSx} {...props} />;
}

export function FullWidthButton({sx, ...props}: ButtonProps) {
    const customSx = {
        color: Colors.dark,
        backgroundColor: Colors.second,
        boxShadow: 0,
        '&:hover': {
            backgroundColor: Colors.secondHover,
            boxShadow: 0,
        },
        paddingTop: '15px',
        paddingBottom: '15px',
        fontSize: '18px',
        ...sx,
    };

    return <Button variant="contained" sx={customSx} {...props} />;
}

export function ImportantLink(
    props: LinkProps & {
        children?: React.ReactNode;
    } & {
        sx?: SxProps;
    }
) {
    const mySx = [
        {
            borderRadius: '4px',
            paddingTop: '15px',
            paddingBottom: '15px',
            color: 'black',
            backgroundColor: Colors.second,
            textDecoration: 'none',
            textAlign: 'center',
            '&:hover': {
                backgroundColor: Colors.secondHover,
            },
            ...props.sx,
        },
    ] as SxProps;

    return (
        <Link href={props.href} style={{textDecoration: 'none'}}>
            <Typography sx={mySx}>{props.children}</Typography>
        </Link>
    );
}

export function CustomLink({children, ...props}: BoxProps & {typo?: TypographyProps}) {
    const typoSx = {cursor: 'pointer', textDecoration: 'underline', ...(props.typo?.sx ?? {})};

    return (
        <Box {...props}>
            <Typography sx={typoSx} {...props.typo}>
                {children}
            </Typography>
        </Box>
    );
}

type MyLinkProps = {
    href: string;
    title: string;
    sx?: TypographyProps['sx'];
};

export function MyLink(props: MyLinkProps) {
    const theme = useTheme();
    const isDark = theme.palette.mode == 'dark';

    return (
        <Link href={props.href} style={{textDecoration: 'none', alignSelf: 'center'}}>
            <Typography
                sx={{
                    color: isDark ? 'white' : 'black',
                    textDecoration: 'none',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: '320px',
                    '&:hover': {
                        textDecoration: 'underline',
                        textDecorationColor: isDark ? 'white' : 'black',
                    },
                    ...props.sx,
                }}
            >
                {props.title}
            </Typography>
        </Link>
    );
}

type MyCustomProps = {
    title: string;
    onClick: () => void;
};

export function MyCustomLink(props: MyCustomProps) {
    const theme = useTheme();
    const isDark = theme.palette.mode == 'dark';

    return (
        <Box onClick={props.onClick}>
            <Typography
                sx={{
                    color: isDark ? 'white' : 'black',
                    textDecoration: 'none',
                    cursor: 'pointer',
                    '&:hover': {
                        textDecoration: 'underline',
                        textDecorationColor: isDark ? 'white' : 'black',
                    },
                }}
            >
                {props.title}
            </Typography>
        </Box>
    );
}

export const DiscordButton = ({
    color,
    variant,
    sx,
    ...otherProps
}: Omit<ButtonProps, 'startIcon'>) => (
    <Button
        variant={variant ?? 'contained'}
        sx={[...(Array.isArray(sx) ? sx : [sx]), {color: 'white'}]}
        startIcon={<DiscordIcon />}
        color={color ?? 'discord'}
        {...otherProps}
    />
);

export function HistoryButton(props: PropsWithChildren<Pick<LinkProps, 'href'>>) {
    return (
        <ImportantLink
            href={props.href}
            sx={{
                backgroundColor: Colors.yellow,
                '&:hover': {backgroundColor: Colors.yellowHover},
                paddingLeft: 3,
                paddingRight: 3,
            }}
        >
            {props.children}
        </ImportantLink>
    );
}
