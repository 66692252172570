/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnonymousParticipation } from '../models/AnonymousParticipation';
import type { CheckOrder } from '../models/CheckOrder';
import type { CommentParticipation } from '../models/CommentParticipation';
import type { CreateOrder } from '../models/CreateOrder';
import type { OrderAddress } from '../models/OrderAddress';
import type { OrderDetails } from '../models/OrderDetails';
import type { OrderOverview } from '../models/OrderOverview';
import type { OrderResponse } from '../models/OrderResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrderService {

    /**
     * Create Order
     * @param requestBody
     * @returns OrderResponse Successful Response
     * @throws ApiError
     */
    public static createOrder(
        requestBody: CreateOrder,
    ): CancelablePromise<OrderResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Check Order
     * @param requestBody
     * @returns OrderOverview Successful Response
     * @throws ApiError
     */
    public static checkOrder(
        requestBody: CheckOrder,
    ): CancelablePromise<OrderOverview> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order/check',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Comment
     * @param requestBody
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static comment(
        requestBody: CommentParticipation,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order/comment',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Anonymous
     * @param requestBody
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static anonymous(
        requestBody: AnonymousParticipation,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order/anonymous',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order
     * @param orderId
     * @returns OrderDetails Successful Response
     * @throws ApiError
     */
    public static getOrder(
        orderId: string,
    ): CancelablePromise<OrderDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order/{order_id}',
            path: {
                'order_id': orderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Orders
     * @returns OrderDetails Successful Response
     * @throws ApiError
     */
    public static getOrders(): CancelablePromise<Array<OrderDetails>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orders',
        });
    }

    /**
     * Cancel Order
     * @param orderId
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static cancelOrder(
        orderId: string,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order/cancel',
            query: {
                'order_id': orderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Modify Address
     * @param requestBody
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static modifyAddress(
        requestBody: OrderAddress,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order/address',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
