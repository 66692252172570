/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BacketCheckError } from '../models/BacketCheckError';
import type { BacketCheckValid } from '../models/BacketCheckValid';
import type { Basket } from '../models/Basket';
import type { BasketItem } from '../models/BasketItem';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BasketService {

    /**
     * Add Basket
     * @param requestBody
     * @returns Basket Successful Response
     * @throws ApiError
     */
    public static addBasket(
        requestBody: BasketItem,
    ): CancelablePromise<Basket> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/basket/add',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Basket
     * @returns Basket Successful Response
     * @throws ApiError
     */
    public static getBasket(): CancelablePromise<Basket> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/basket',
        });
    }

    /**
     * Modify Basket
     * @param requestBody
     * @returns Basket Successful Response
     * @throws ApiError
     */
    public static modifyBasket(
        requestBody: BasketItem,
    ): CancelablePromise<Basket> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/basket',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Empty Basket
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static emptyBasket(): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/basket',
        });
    }

    /**
     * Check Basket
     * @param campaignId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static checkBasket(
        campaignId: string,
    ): CancelablePromise<(BacketCheckValid | BacketCheckError)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/basket/check',
            query: {
                'campaign_id': campaignId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
